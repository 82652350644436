<template>
  <div>
    <v-card id="quotation-list">
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <div class="d-flex align-center pb-5">
          <!-- create quotation -->
          <v-btn
            v-if="can('write', 'Quotation')"
            color="primary"
            class="me-3 quotation-button"
            :to="{ name: 'apps-quotation-add' }"
          >
            <v-icon
              size="18"
              class="me-1"
            >
              {{ icons.mdiPlus }}
            </v-icon>
            <span>{{ t('Create Quotation') }}</span>
          </v-btn>
        </div>

        <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>

        <div class="row text-right offset-0 pb-sm-6 pb-5">
          <div class="col-sm-4 pa-2 offset-sm-3 col-6">
            <v-autocomplete
              v-model="toggleSelected"
              :items="branchOptions"
              item-text="name"
              item-value="value"
              single-line
              outlined
              dense
              hide-details
              clearable
              :placeholder="t('Select Branch Status')"
              @change="activeToggleChange()"
            ></v-autocomplete>
          </div>

          <div class="col-sm-5 col-6 pa-2">
            <!-- filter branch -->
            <v-combobox
              v-model="branchFilters"
              :items="branches"
              item-text="name"
              single-line
              multiple
              outlined
              dense
              hide-details
              clearable
              :placeholder="t('Select Branch')"
              class="quotation-list-branch"
            ></v-combobox>
          </div>
        </div>
      </v-card-text>

      <v-card-text class="row pb-0 pr-0">
        <div class="col-sm-2 col-12 pb-sm-8 pb-1 pl-2 pr-1">
          <!-- Date range -->
          <v-menu
            v-model="isDateMenuOpen"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :append-icon="icons.mdiCalendar"
                :value="dateRangeText"
                readonly
                dense
                outlined
                hide-details
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dateRange"
              color="primary"
              :first-day-of-week="1"
              range
            >
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                block
                @click="dateRangeChange()"
              >
                Set dates
              </v-btn>
            </v-date-picker>
          </v-menu>
        </div>

        <v-spacer></v-spacer>

        <div class="d-flex align-center col-sm-4 col-12 pb-sm-8 pb-1 pr-1">
          <!-- search quotation -->
          <v-tooltip
            :left="$vuetify.breakpoint.smAndUp"
            :top="$vuetify.breakpoint.xsOnly"
            :nudge-bottom="$vuetify.breakpoint.smAndUp ? 20 : 0"
            :nudge-top="$vuetify.breakpoint.xsOnly ? 5 : 0"
            :nudge-right="$vuetify.breakpoint.xsOnly ? 100 : 0"
            max-width="250"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-avatar
                size="30"
                class="mr-3 hover-pointer"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon size="25">
                  {{ icons.mdiAlertCircleOutline }}
                </v-icon>
              </v-avatar>
            </template>
            <h3 class="my-2">
              {{ t('How to use the search bar') }}
            </h3>
            <p class="mb-1">
              {{ t('You can search using') }}:
            </p>
            <ul class="mb-2">
              <li>
                {{ t('#Quotation No.') }}
              </li>
              <li>
                {{ t('Car Registration No.') }}
              </li>
              <li>
                {{ t('Company Name') }}
              </li>
            </ul>
          </v-tooltip>
          <v-text-field
            v-model="searchText"
            :append-icon="icons.mdiMagnify"
            single-line
            dense
            outlined
            hide-details
            :placeholder="t('Search Quotation')"
            :class="{ 'quotation-list-search': $vuetify.breakpoint.mdAndUp }"
          ></v-text-field>
        </div>
        <div class="d-flex align-center col-sm-2 col-12 pl-3 pb-sm-8 pb-6 pl-0 pr-1">
          <v-btn
            block
            color="primary"
            @click="searchQuotations"
          >
            <v-icon
              class="pr-2"
              size="30"
            >
              {{ icons.mdiMagnify }}
            </v-icon>
            Search
          </v-btn>
        </div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text class="pt-5">
        <v-row>
          <v-col
            lg="2"
            cols="3"
          >
            <div class="d-flex align-center">
              <v-select
                v-model="tablePagination.per"
                :items="listLengthOptions"
                single-line
                outlined
                dense
                hide-details
                class="quotation-list-row-selection"
                @input="listLengthChange()"
              ></v-select>
              <div class="pl-3">
                {{ t('Rows') }}
              </div>
            </div>
          </v-col>

          <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="tablePagination.current_page"
              total-visible="6"
              :length="tablePagination.total_page"
              @input="pageChange()"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>

      <!-- table -->
      <v-data-table
        :headers="tableColumns"
        :items="quotations"
        :options.sync="options"
        :items-per-page="100"
        :loading="loading"
        mobile-breakpoint="0"
        hide-default-footer
        :disable-sort="true"
        class="text-no-wrap"
      >
        <!-- Actions -->
        <template #[`item.actions`]="{item}">
          <div class="d-flex align-center justify-center">
            <!-- Edit -->
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  :to="{
                    name: (can('write', 'ReportActions') ? 'apps-quotation-edit' : 'apps-quotation-req'),
                    params: { id: item.id }
                  }"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon size="18">
                    {{ icons.mdiPencilOutline }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>

            <!-- Void -->
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click="voidableQuotation = item; isVoidDialogVisible = true"
                >
                  <v-icon size="18">
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Void</span>
            </v-tooltip>
          </div>
        </template>

        <!-- Quotation Number -->
        <template #[`item.quotation_number`]="{item}">
          <router-link
            class="font-weight-medium text-decoration-none"
            :to="{ name: 'apps-quotation-preview', params: { id: item.id } }"
          >
            {{ item.quotation_number }}
          </router-link>
        </template>
      </v-data-table>

      <!-- Void dialog -->
      <v-dialog
        v-model="isVoidDialogVisible"
        width="500"
      >
        <v-card>
          <v-card-title class="d-flex align-center mv-4">
            <div
              v-if="!can('write', 'ReportActions')"
              class="pr-1"
            >
              Request
            </div>
            Void {{ voidableQuotation.quotation_number }}?
            <v-spacer></v-spacer>
            <v-btn
              icon
              small
              @click="isVoidDialogVisible = false"
            >
              <v-icon size="22">
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text v-if="can('write', 'ReportActions')">
            This quotation will be voided. It won't be taken into any calculations.
          </v-card-text>

          <v-card-text v-else>
            <v-textarea
              v-model="newComment"
              dense
              outlined
              hide-details="auto"
              placeholder="Leave your comment here (optional)"
              :disabled="loading"
              class="mb-4"
            ></v-textarea>
            This action will create a void request and this quotation will be voided after approved by auditor.
          </v-card-text>

          <v-card-actions>
            <v-btn
              v-if="can('write', 'ReportActions')"
              color="error"
              block
              :loading="voidLoading"
              :disabled="voidLoading"
              class="mt-3"
              @click="voidQuotation()"
            >
              Yes, remove
            </v-btn>

            <v-btn
              v-else
              color="error"
              block
              :loading="voidLoading"
              :disabled="voidLoading"
              class="mt-3"
              @click="createRequest()"
            >
              Yes, send request
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-card-text class="pt-5">
        <v-row>
          <v-col
            lg="2"
            cols="3"
          >
            <div class="d-flex align-center">
              <v-select
                v-model="tablePagination.per"
                :items="listLengthOptions"
                single-line
                outlined
                dense
                hide-details
                class="quotation-list-row-selection"
                @input="listLengthChange()"
              ></v-select>
              <div class="pl-3">
                {{ t('Rows') }}
              </div>
            </div>
          </v-col>

          <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="tablePagination.current_page"
              total-visible="6"
              :length="tablePagination.total_page"
              @input="pageChange()"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-row class="my-5">
      <v-col
        cols="12"
        class="d-flex justify-end"
      >
        <v-btn
          :block="$vuetify.breakpoint.xsOnly"
          :loading="loadingPrint"
          :disabled="loadingPrint"
          color="primary"
          class="quotation-button"
          :class="{ 'loading-color': loadingPrint && !$vuetify.theme.dark }"
          @click="printReport()"
        >
          <v-icon
            v-if="!loadingPrint"
            size="18"
            class="me-2"
          >
            {{ icons.mdiPrinter }}
          </v-icon>
          <span>{{ t('Print Report') }}</span>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  mdiPlus,
  mdiCheckCircleOutline,
  mdiAlertCircleOutline,
  mdiDeleteOutline,
  mdiPencilOutline,
  mdiCalendar,
  mdiPrinter,
  mdiMagnify,
  mdiClose,
} from '@mdi/js'
import {
  onUnmounted,
  onMounted,
  ref,
  inject,
  computed,
} from '@vue/composition-api'
import { useRouter } from '@core/utils'
import ability from '@/plugins/acl/ability'
import quotationStoreModule from './quotationStoreModule'

export default {
  setup() {
    // Initializers
    const QUOTATION_APP_STORE_MODULE_NAME = 'app-quotation'
    const store = inject('store')
    const snackbarService = inject('snackbarService')
    const t = inject('t')
    const can = inject('can')
    const { router } = useRouter()
    const isAuditor = ability.can('read', 'ReportSummaries')
    const format = isAuditor ? 'csv' : 'pdf'

    if (!store.hasModule(QUOTATION_APP_STORE_MODULE_NAME)) {
      store.registerModule(QUOTATION_APP_STORE_MODULE_NAME, quotationStoreModule)
    }

    // Table Handlers
    const tableColumns = computed(() => (
      [
        { text: t('Actions'), value: 'actions', align: 'center' },
        { text: t('#Quotation No.'), value: 'quotation_number' },
        { text: t('Issue Date'), value: 'created_at' },
        { text: t('Company Name'), value: 'company_name' },
        { text: t('Car Registration No.'), value: 'car_registration_number' },
        { text: t('Surplus Hibah'), value: 'surplus_hibah' },
        { text: t('NCD'), value: 'no_claim_discount' },
        { text: t('Sum Insured'), align: 'right', value: 'sum_insured' },
        { text: t('Insurance Premium'), align: 'right', value: 'insurance_premium' },
        { text: t('After Discount'), align: 'right', value: 'after_discount' },
        { text: t('Road Tax'), align: 'right', value: 'road_tax' },
        { text: t('Additional Fees'), align: 'right', value: 'additional_fees' },
        { text: t('Total'), align: 'right', value: 'total' },
        { text: t('Branch'), value: 'branch' },
        { text: t('Insurance Product'), value: 'insurance_product' },
        { text: t('Insurance'), value: 'insurance' },
        { text: t('Pickup Location'), value: 'pickup_location' },
        { text: t('Period'), value: 'period' },
        { text: t('Issuer'), value: 'issuer' },
        { text: t('General Remarks'), value: 'general_remarks' },
      ]
    ))

    // Properties
    const [dayNow, monthNow, yearNow] = new Date().toLocaleDateString('en-SG').split('/')
    const quotations = ref([])
    const tablePagination = ref({
      current_page: 1,
      per: 20,
      total_count: 0,
      total_page: 0,
    })
    const searchText = ref('')
    const options = ref({
      sortBy: ['id'],
      sortDesc: [false],
    })
    const loading = ref(false)
    const loadingPrint = ref(false)
    const userData = JSON.parse(localStorage.getItem('userData'))
    const newComment = ref(null)
    const toggleSelected = ref('active')
    const branchOptions = ref([
      { name: 'Ongoing branches', value: 'active' },
      { name: 'Closed branches', value: 'inactive' },
    ])
    const branchFilters = ref([])
    const dateRange = ref([`${yearNow}-${monthNow}-${dayNow}`])
    const listLengthOptions = ref([20, 50, 100])
    const isDateMenuOpen = ref(false)
    const isVoidDialogVisible = ref(false)
    const voidableQuotation = ref({})
    const voidLoading = ref(false)

    // Utils
    const formatDate = date => {
      if (!date) return null
      const [year, month, day] = date.split('-')

      return `${day}/${month}/${year}`
    }

    // Computed
    const branches = computed(() => {
      switch (toggleSelected.value) {
        case 'active':
          return store.state.branches.filter(b => b.active)
        case 'inactive':
          return store.state.branches.filter(b => b.active === false)
        default:
          return store.state.branches
      }
    })
    const dateRangeText = computed(() => {
      if (Date.parse(dateRange.value[0]) > Date.parse(dateRange.value[1])) {
        const [start, end] = dateRange.value
        dateRange.value[0] = end
        dateRange.value[1] = start
      }

      const startDateText = formatDate(dateRange.value[0])
      const showEndDate = dateRange.value[0] !== dateRange.value[1] && formatDate(dateRange.value[1]) !== null
      const endDateText = showEndDate ? ` - ${formatDate(dateRange.value[1])}` : ''

      return `${startDateText}${endDateText}`
    })

    // Methods
    const isLoading = () => {
      loading.value = true
      snackbarService.info('Loading your list. Please hold...')
    }
    const doneLoading = () => {
      loading.value = false
      snackbarService.success('Done loading your list!')
    }
    const fetchQuotations = () => {
      store
        .dispatch('app-quotation/fetchQuotations', {
          page: tablePagination.value.current_page,
          per: tablePagination.value.per,
          search_text: searchText.value,
          branch_ids: branchFilters.value.map(branch => branch.id),
          start_date: dateRange.value[0],
          end_date: dateRange.value[1] ? dateRange.value[1] : dateRange.value[0],
          branch_toggle_filter: toggleSelected.value,
        })
        .then(response => {
          const { data, pagination } = response.data
          quotations.value = data
          quotations.value.forEach(field => {
            Object.keys(field).forEach(key => {
              if (field[key] === null) field[key] = 'NULL'
            })
          })
          tablePagination.value = pagination
          doneLoading()
        })
        .catch(error => {
          loading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching quotations. Please refresh!')
        })
    }
    const pageChange = () => {
      isLoading()
      fetchQuotations()
    }
    const listLengthChange = () => {
      tablePagination.value.current_page = 1
      isLoading()
      fetchQuotations()
    }
    const dateRangeChange = () => {
      isDateMenuOpen.value = false
      tablePagination.value.current_page = 1
      isLoading()
      fetchQuotations()
    }
    const activeToggleChange = () => {
      branchFilters.value = []
    }
    const printReport = () => {
      loadingPrint.value = true
      store
        .dispatch('app-quotation/printReport', {
          search_text: searchText.value,
          branch_ids: branchFilters.value.map(branch => branch.id),
          start_date: dateRange.value[0],
          end_date: dateRange.value[1] ? dateRange.value[1] : dateRange.value[0],
          branch_toggle_filter: toggleSelected.value,
          format,
        })
        .then(response => {
          const url = window.URL.createObjectURL(response)
          const a = document.createElement('a')
          a.href = url
          a.download = `quotation(${dateRangeText.value}).${format}`
          document.body.appendChild(a)
          a.click()
          a.remove()
          loadingPrint.value = false
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while printing report. Please refresh!')
        })
    }
    const voidQuotation = () => {
      voidLoading.value = true
      store
        .dispatch('app-quotation/voidQuotation', { id: voidableQuotation.value.id })
        .then(response => {
          snackbarService.success(response.data.message)
          fetchQuotations()
          isVoidDialogVisible.value = false
          voidLoading.value = false
        })
        .catch(error => {
          voidLoading.value = false
          snackbarService.error(error.data.message || 'Something went wrong while voiding quotation. Please refresh!')
        })
    }
    const createComment = id => {
      store
        .dispatch('requestStore/createComment', {
          user_id: userData.id,
          approval_request_id: id,
          comment: newComment.value,
        })
        .then(response => {
          snackbarService.success(response.data.message)
          router.push('/apps/requests/list')
        })
        .catch(error => {
          loading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while saving comment. Please refresh!')
        })
    }
    const createRequest = () => {
      store
        .dispatch('requestStore/createRequest', {
          record_id: voidableQuotation.value.id,
          record_type: 'Quotation',
          requested_by_id: userData.id,
          action: 'Void',
        })
        .then(response => {
          if (newComment.value) {
            createComment(response.data.data.id)
          } else {
            snackbarService.success(response.data.message)
            router.push('/apps/requests/list')
          }
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while saving request. Please refresh!')
        })
    }
    const searchQuotations = () => {
      isLoading()
      fetchQuotations()
    }

    onMounted(() => searchQuotations())

    // Unmounted
    onUnmounted(() => {
      if (store.hasModule(QUOTATION_APP_STORE_MODULE_NAME)) store.unregisterModule(QUOTATION_APP_STORE_MODULE_NAME)
    })

    return {
      t,
      can,
      loading,
      loadingPrint,
      quotations,
      branches,
      tablePagination,
      tableColumns,
      isDateMenuOpen,
      listLengthOptions,
      options,
      searchText,
      toggleSelected,
      branchOptions,
      branchFilters,
      dateRange,
      dateRangeText,
      fetchQuotations,
      pageChange,
      listLengthChange,
      dateRangeChange,
      activeToggleChange,
      formatDate,
      printReport,
      isVoidDialogVisible,
      voidableQuotation,
      voidLoading,
      voidQuotation,
      createRequest,
      newComment,
      searchQuotations,

      icons: {
        mdiPlus,
        mdiCheckCircleOutline,
        mdiAlertCircleOutline,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiCalendar,
        mdiPrinter,
        mdiMagnify,
        mdiClose,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
  #quotation-list {
    .quotation-list-search {
      width: 29rem;
    }

    .quotation-list-branch {
      max-width: 33rem;
    }

    .quotation-list-row-selection {
      max-width: 5.3rem;
    }
  }

  .quotation-button {
    width: 251px;
  }

  ::v-deep .theme--dark.v-btn.v-btn--disabled.v-btn--has-bg.loading-color {
    background: #d3d3d3 !important;
    color: #eee !important;
  }
</style>
